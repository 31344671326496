<template>
  <a-modal
    :title="handle === 'add' ? '新增' : '编辑'"
    style="top: 8px"
    :width="1000"
    v-model="visible"
    @ok="toSubmit"
    okText="提交"
    :maskClosable="false"
  >
    <a-form-model
      ref="form"
      :model="rowData"
      :rules="formRule"
      layout="horizontal"
      :label-col="{ span: 3 }"
      :wrapper-col="{ span: 21 }"
    >
      <a-form-model-item label="经销商" prop="dealerId">
        <a-input v-model="rowData.dealerId" placeholder="经销商id"></a-input>
      </a-form-model-item>
      <a-form-model-item label="临时申请额度" prop="(temporaryQuota">
        <a-input v-model="rowData.temporaryQuota" placeholder="临时申请额度"></a-input>
      </a-form-model-item>
      <a-form-model-item label="是否通过" prop="(status">
        <a-input v-model="rowData.status" placeholder="是否通过"></a-input>
      </a-form-model-item>
      <a-form-model-item label="申请原因" prop="(applyForReasons">
        <a-input v-model="rowData.applyForReasons" placeholder="申请原因"></a-input>
      </a-form-model-item>
      <a-form-model-item label="驳回原因" prop="(auditReason">
        <a-input v-model="rowData.auditReason" placeholder="驳回原因"></a-input>
      </a-form-model-item>
      <a-form-model-item label="创建时间" prop="(createTime">
        <a-input v-model="rowData.createTime" placeholder="创建时间"></a-input>
      </a-form-model-item>
      <a-form-model-item label="创建人" prop="(createUser">
        <a-input v-model="rowData.createUser" placeholder="创建人"></a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {
  editDealerTemporaryQuotaRecord,
  selectByIdDealerTemporaryQuotaRecord,
  addDealerTemporaryQuotaRecord,
} from '../api/DealerTemporaryQuotaRecordApi'
export default {
  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      // 表单验证
      formRule: {
        id: [{ required: true, message: '请输入id主键', trigger: 'blur' }],
        dealerId: [{ required: true, message: '请输入经销商id', trigger: 'blur' }],
        temporaryQuota: [{ required: true, message: '请输入临时申请额度', trigger: 'blur' }],
        status: [{ required: true, message: '请输入是否通过：0草稿 1待审核 2审核通过 3审核拒绝', trigger: 'blur' }],
        applyForReasons: [{ required: true, message: '请输入申请原因', trigger: 'blur' }],
        auditReason: [{ required: true, message: '请输入审核原因', trigger: 'blur' }],
        createTime: [{ required: true, message: '请输入创建时间', trigger: 'blur' }],
        createUser: [{ required: true, message: '请输入创建人', trigger: 'blur' }],
        createUserId: [{ required: true, message: '请输入创建人id', trigger: 'blur' }],
        modifyTime: [{ required: true, message: '请输入修改时间', trigger: 'blur' }],
        modifyUser: [{ required: true, message: '请输入修改人', trigger: 'blur' }],
        remarks: [{ required: true, message: '请输入备注', trigger: 'blur' }],
        modifyUserId: [{ required: true, message: '请输入修改人id', trigger: 'blur' }],
        flagDel: [{ required: true, message: '请输入是否删除', trigger: 'blur' }],
        expireDate: [{ required: true, message: '请输入过期时间', trigger: 'blur' }],
        remainingNum: [{ required: true, message: '请输入剩余数量', trigger: 'blur' }],
      },
    }
  },
  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true
      this.rowData = row
    },
    /**
     * 表单提交
     */
    toSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        const res =
          this.handle === 'add'
            ? await addDealerTemporaryQuotaRecord(this.rowData)
            : await editDealerTemporaryQuotaRecord(this.rowData)
        if (res.code === 200) {
          this.$notification.success({ message: res.message })
          this.$emit('reload')
          this.visible = false
          this.rowData = {}
        } else {
          this.$notification.error({ message: res.message })
        }
      })
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
</style>
