/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-07-14 15:23:58
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-16 17:01:27
 */
export const columns = [
{
    title: '经销商',
    dataIndex: 'dealerName',
    key: 'dealerName',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '临时申请额度',
    dataIndex: 'temporaryQuota',
    key: 'temporaryQuota',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '审核状态',
    dataIndex: 'status',
    key: 'status',
    /*width:150,*/
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'status' }
},
{
    title: '申请原因',
    dataIndex: 'applyForReasons',
    key: 'applyForReasons',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
  title: '有效期',
  dataIndex: 'expireDate',
  key: 'expireDate',
  width:250,
  align: 'center',
  // ellipsis:true,
  scopedSlots: {customRender: 'expireDate'}
},
{
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '创建人',
    dataIndex: 'createUser',
    key: 'createUser',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
// {
//     title: '状态',
//     dataIndex: 'quotaStatus',
//     key: 'quotaStatus',
//     /*width:150,*/
//     align:'center',
//     ellipsis:true,
//     scopeSlots: { customRender: 'quotaStatus' }
// },
]
