/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-07-14 15:23:58
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-14 15:28:31
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listDealerTemporaryQuotaRecord = params => axios({
    url: '/api/base/customer/dealerTemporaryQuotaRecord/firmQuotaList',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addDealerTemporaryQuotaRecord = params => axios({
    url: '/api/base/customer/dealerTemporaryQuotaRecord/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editDealerTemporaryQuotaRecord = params => axios({
    url: '/api/dealer_temporary_quota_record/system/dealerTemporaryQuotaRecord/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delDealerTemporaryQuotaRecord = params => axios({
    url:'/api/base/customer/dealerTemporaryQuotaRecord/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdDealerTemporaryQuotaRecord = params => axios({
    url: '/api/base/customer/dealerTemporaryQuotaRecord/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
